import React from "react";
import AdminLayout from "../../components/admin/adminLayout";
import LogoutComponent from "../../components/admin/logoutComponent";
import SEO from "../../components/seo";

const Logout = (props) =>
{
  return (
    <AdminLayout>
      <SEO
        keywords = { [`LawChest`, `admin`, `logout`] }
        title = "Logout"
        isAdmin = { true }
      />
      <LogoutComponent />
    </AdminLayout>
  );
};

export default Logout;