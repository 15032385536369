import React from "react";
import { isLoggedIn, logout } from "../../utils/auth";

const LogoutComponent = () =>
{
  if (isLoggedIn())
  {
    logout(null);
  }

  return (
    <div>
      Logged out
    </div>
  );
};

export default LogoutComponent;